import React from 'react';
import { Typography, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Paper, Table, TablePagination, 
    TableSortLabel, FormControlLabel,
    Switch } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BlockIcon from '@material-ui/icons/Block';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import { formatDate } from '../../../services/TimeService';


function renderStatus(status){
    if (status === 'completed') {
        return (
            <Typography 
                // component="h6"
                // variant='body1' 
                >
                    <CheckIcon style={{ color: "#58B402" }}/>
                    &nbsp;
                    Завершён
            </Typography>
            )
    }
    if (status === 'delivering') {
        return (
            <Typography 
                // component="h6"
                // variant='body1' 
                >
                    <LocalShippingOutlinedIcon style={{ color: "#06AEE7" }} />
                    &nbsp;
                    Доставляется
            </Typography>
            )
    }
    if (status === 'scheduled') {
        return (
            <Typography 
                // component="h6"
                // variant='body1' 
                >
                    <ScheduleIcon style={{ color: "#725CA4" }} />
                    &nbsp;
                    Запланирован
            </Typography>
            )
    }
    if (status === 'canceled') {
        return (
            <Typography 
                // component="h6"
                // variant='body1' 
                >
                    <BlockIcon style={{ color: "#EF3F3F" }} /> 
                    &nbsp;
                    Отменён
            </Typography>
            ) 
    }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
  { id: 'cargo_description', numeric: false, disablePadding: false, label: 'Название заказа' },
  { id: 'created_date', numeric: false, disablePadding: false, label: 'Дата' },
  { id: 'cargo_type', numeric: false, disablePadding: false, label: 'Тип отправления' },
  { id: 'delivery_company', numeric: false, disablePadding: false, label: 'Курьерская компания' },
  { id: 'sender_ciry', numeric: false, disablePadding: false, label: 'Город отправления' },  
  { id: 'recipient_city', numeric: false, disablePadding: false, label: 'Город доставки' },
  { id: 'cost', numeric: false, disablePadding: false, label: 'Цена' },

  { id: 'status', numeric: false, disablePadding: false, label: 'Статус' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    width: '99%',
    marginLeft: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function OrdersTable(props) {
  const rows = props.orders;
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  async function handleClick(event, id) {
    navigate(`order/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer maxWidth='lg'>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                            
                      {/* <TableCell component="th" id={row.id} scope="row">
                        {row.id}
                      </TableCell> */}
                      <TableCell>{row.cargo_description}</TableCell>
                      <TableCell>{formatDate(row.created_date)}</TableCell>
                      <TableCell>{row.cargo_type}</TableCell>
                      <TableCell>{row.delivery_company}</TableCell>
                      <TableCell>{row.sender_city}</TableCell>
                      <TableCell>{row.recipient_city}</TableCell>
                      <TableCell align="center">{row.cost}</TableCell>
                      <TableCell align="inherit">{row.status}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
