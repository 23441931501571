import React from 'react'
import { Box, Container, Button,} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    newOrderBnt: {
        color: "#2184D1",
        // backgroundColor: "#2184D1",
        borderColor: "#2184D1",
        float: 'right'
       
    },
    newOrderBlock: {
        flexGrow: 1,
        position: 'flex',
    }

}));



export default function NewOrderBtn() {
    const classes = useStyles();
    let navigate = useNavigate();

    async function handleClick(event, id) {
        navigate(`calc-new-order/`);
        console.log(id)
      };

    return (
        <Container fixed className={classes.newOrderBlock} >
            <Box>
                <Button  
                    className={classes.newOrderBnt} 
                    variant="outlined"
                    onClick={(event) => handleClick(event)}
                    startIcon={<AddIcon />}
                    >
                    Создать Заказ
                </Button>
            </Box>
        </Container>
    )
}
