import React from 'react'
import { Container, Paper, Grid, Typography, Button, Card, CardHeader, Avatar, CardContent, IconButton, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainBlockImg from '../assets/Imag_1.svg';
import ProcessScheme from '../assets/ProcessScheme.png';
import { useNavigate } from "react-router-dom";
// import Round3 from '../assets/3round.svg';
// import Round2 from '../assets/2round.svg';
// import Round1 from '../assets/1round.svg';

import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import BottomLogo999 from '../assets/bottomLogo/999.png';
import BottomLogoJoblist from '../assets/bottomLogo/joblist.png';
import BottomLogoPrice from '../assets/bottomLogo/price.png';
import BottomLogoProfi from '../assets/bottomLogo/profi.png';
import BottomLogoSimpals from '../assets/bottomLogo/simpals.png';
import BottomLogoSwiss from '../assets/bottomLogo/swiss.png';
import BottomLogoOptim from '../assets/bottomLogo/optim.png';
import BottomLogoHelvetas from '../assets/bottomLogo/helvetas.png';


// import Partner1 from '../assets/partners/image9.png';
// import Partner2 from '../assets/partners/logoCR.png';
// import Partner3 from '../assets/partners/logoFC.png';
// import Partner4 from '../assets/partners/LogoFS.png';
// import Partner5 from '../assets/partners/LogoMC.png';

// import YouGettingBlockBG from '../assets/YouGettingBlockBG.png';
// import MapDelivery from '../assets/MapDelivery.png'; 

import LastBlockImg from '../assets/LastBlockImg.png';


const useStyles = makeStyles((theme) => ({
    mainBlockTitle: {
        left: '405px',
        top: '236px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23',
        marginTop: theme.spacing(6),
    },
    mainBlockTitle2: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23'
    },
    mainBlockTitle3: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#4D4E54',
        marginBottom: theme.spacing(6),
    },
    mainBlock: {
        position: 'relative',
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(10),
          },
    },
    mainBlockImg: {
        // position: 'absolute',
        height: '100%',
        // height: '503px',
        // left: '880px',
        // top: '166px',
        [theme.breakpoints.down("sm")]: {
            display: "none",
          },
    },
    footerIcon: {
        display: 'flex'
    },
    processSchemeBlockImg: {
        width: '100%'
    },
    processSchemeBlock:{
        marginBottom: theme.spacing(10),
    },

    processSchemeBlockTitle:{
        textAlign: 'left',
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
          },
    },

    howConnectBlockTitle:{
        padding: theme.spacing(10),
        top: theme.spacing(10),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
            top: theme.spacing(5),
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(2),
          },
    },
    howConnectBlock: {
        backgroundColor: "#FAFBFC",
        marginBottom: theme.spacing(10),
    },
    avatar: {
        backgroundColor: "#007FE1",
    },
    youGettingBlock: {
        backgroundColor: "#FAFBFC",
        marginBottom: theme.spacing(10),
    },
    mapBlock:{
        backgroundColor: "#F8F5EE",
        marginBottom: theme.spacing(10),
    },
    lastBlock:{
        height: 1200,
        backgroundImage: `url(${LastBlockImg})`,
        // marginBottom: theme.spacing(100),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: `calc(100vw + 48px)`,
        margin: -24,
        padding: 24,
    },
    bottomLogoBlock: {
        margin: '40px 0',        
    },
    logoOptim: {
        margin: '0 0 0 20px'
    },
    bottom1Items: {
        marginLeft: theme.spacing(10),
    },
    cooperativeText: {
        fontSize: '12px'
    }
}));

export default function LandingMain() {

    const classes = useStyles();

    let navigate = useNavigate();

    async function handleLinkTo(event, pathTo){
        navigate(pathTo);
    }

    // console.log("ENV", process.env.NODE_ENV);

    return (
        <main>
            <div className={classes.mainBlock}>
                <Container maxWidth="md" >
                    <Grid container>
                        <Grid item lg={5} xl={5} md={5} sm={12}>
                            <div>
                                <Typography
                                className={classes.mainBlockTitle}
                                component="h1"
                                variant='h3'
                                color="inherit"
                                gutterBottom
                                >
                                    Агрегатор доставки
                                </Typography>
                                <Typography
                                className={classes.mainBlockTitle2}
                                component="h3"
                                variant='h5'
                                color="inherit"
                                gutterBottom
                                >
                                    Все службы доставок здесь
                                </Typography>
                                <Typography
                                className={classes.mainBlockTitle3}
                                component="h5"
                                variant='body1'
                                color="inherit"
                                gutterBottom
                                >
                                    Выберите самый удобный для вас способ доставки. 
                                    Сберегите время и сократите расходы на логистику.
                                </Typography>

                                <Button variant="contained" color="primary" disableElevation>
                                    Расчитать стоимость доставки
                                </Button>

                            </div>
                            
                        </Grid>
                        <Grid item lg={5} xl={5} md={5} sm={12} align='right'>
                            <img className={classes.mainBlockImg} alt='' src={MainBlockImg}/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.processSchemeBlock}>
                <Container maxWidth="md">
                    <Grid container>
                        <Grid item md={12} sm={12} >

                                <Typography
                                className={classes.processSchemeBlockTitle}
                                component="h1"
                                variant='h3'
                                color="inherit"
                                gutterBottom
                                >
                                    Как устроен процесс доставки
                                </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} align='center'>
                            <img className={classes.processSchemeBlockImg} alt='' src={ProcessScheme}/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.howConnectBlock}>
                <Container fixed justify='center'>
                    <Grid container className={classes.howConnectBlockTitle}>
                        <Grid item md={12} sm={12} >

                                <Typography
                                    component="h1"
                                    variant='h3'
                                    color="inherit"
                                    align='center'
                                    gutterBottom
                                >
                                    Как подключиться
                                </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={9} justifyContent='center'>
                        <Grid item md={4} sm={12} >
                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        1
                                    </Avatar>
                                    }
                                    
                                    title="Заполните заявку на подключение"
                                />

                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Для того, чтобы подать заявку, ответьте на вопросы нашей анкеты и расскажите  о вашем магазине.
                                    </Typography>
                                </CardContent>
                            </Card>


                        </Grid>
                        <Grid item md={4} sm={12} >

                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        2
                                    </Avatar>
                                    }
                                    
                                    title="Наш менеджер с вами свяжется"
                                />

                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Ваша заявка будет рассмотенна в кратчайшие сроки менеджером 999Delivey который с вами свяжется чтобы обсудить детали подключения.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4} sm={12} >

                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        3
                                    </Avatar>
                                    }
                                    
                                    title="Подпишите договор интеграции сервиса"
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Подпишите договор интеграции сервиса и получите доступ в личный кабинет или API для вашего онлайн магазина.
                                    </Typography>
                                </CardContent>
                            </Card>
                                
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/* <div className={classes.partnersBlock}>
            <Container fixed justify='center'>
                    <Grid container className={classes.howConnectBlockTitle}>
                        <Grid item md={12} sm={12} >

                                <Typography
                                
                                component="h1"
                                variant='h3'
                                color="inherit"
                                align='center'
                                gutterBottom
                                >
                                    Наши партнёры
                                </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={9} justifyContent='center'>
                        <Grid item md={4} sm={12} align="center">
                        <img  src={Partner1} alt='' width="50%"/>
                        </Grid>
                        <Grid item md={4} sm={12} align="center">
                        <img src={Partner2} alt='' width="70%"/>
                        </Grid>
                        <Grid item md={4} sm={12} align="center">
                        <img src={Partner3} alt='' width="70%"/>
                        </Grid>
                        <Grid item md={4} sm={12} align="center">
                        <img src={Partner4} alt='' width="70%"/>
                        </Grid>
                        <Grid item md={4} sm={12} align="center">
                        <img src={Partner5} alt='' width="70%"/>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}
            <div className={classes.youGettingBlock} >
                <Container fixed>
                    <Grid container className={classes.howConnectBlockTitle}>
                        <Grid item md={12} sm={12} >

                                <Typography
                                
                                component="h1"
                                variant='h3'
                                color="inherit"
                                align='left'
                                gutterBottom
                                >
                                    Что вы получаете
                                </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={9} justifyContent='center'>
                        <Grid item md={4} sm={12} align="left">
                            <Typography variant="h6" component="p">
                                Индивидуальный, выгодный тариф для каждого заказа
                            </Typography>                                    
                            <Typography variant="body2" color="textSecondary" component="p">
                            Стоимость доставки расчитывается иднивидуально для каждого заказа. Укажите параметры и адрес доставки и выберите самое выгодное предложение из всех доступных служб доставки
                            </Typography>
                        </Grid>
                        <Grid item md={4} sm={12} align="left">
                            <Typography variant="h6" component="p">
                                Работайте сразу с несколькими службами доставок
                            </Typography>                                    
                            <Typography variant="body2" color="textSecondary" component="p">
                                Вы заключаете только один контракт, благодоря которому, у вас есть возможность работы со всеми доступными сервисами доставки агрегатора и отдавать предпочтение любому из них при каждом заказе.
                            </Typography>
                        </Grid>
                        <Grid item md={4} sm={12} align="left">
                            <Typography variant="h6" component="p">
                                Выбериту удобный для вас способ интергации
                            </Typography>                                    
                            <Typography variant="body2" color="textSecondary" component="p">
                                Вы можете использовать личный кабинет, для того чтобы оформлять заказы или интегрировать агрегатор при помощи API на сайт вашего магазина.
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>
            </div>
            {/* <div className={classes.mapBlock} >
                <Container fixed>
                    <Grid container spacing={9} justifyContent='center'>
                        <Grid item md={4} sm={12} align="left">
                                <Typography
                                
                                component="h1"
                                variant='h3'
                                color="inherit"
                                align='left'
                                gutterBottom
                                >
                                    Пункты самовывоза 
                                </Typography>
                        </Grid>
                        <Grid item md={8} sm={12} align="left"> 
                        <img src={MapDelivery} alt='' width="70%"/>
                        </Grid>


                    </Grid>
                </Container>
            </div> */}
            <Paper className={classes.lastBlock} elevation={0}>
            <Container maxWidth="md" >
                    <Grid container align='left'>
                        <Grid item md={6} sm={12} ></Grid>
                        <Grid item md={6} sm={12} >
                            <div>
                                <Typography
                                className={classes.mainBlockTitle1}
                                component="h3"
                                variant='h3'
                                color="inherit"
                                gutterBottom
                                >
                                    Работайте как вам удобно
                                </Typography>
                                <Typography
                                className={classes.mainBlockTitle3}
                                component="h5"
                                variant='body1'
                                color="inherit"
                                gutterBottom
                                >
                                    Оформляйте заказы в личном кабинете, отслеживайте статус заказа и детали доставки.
                                </Typography>

                                <Typography
                                className={classes.mainBlockTitle3}
                                component="h5"
                                variant='body1'
                                color="inherit"
                                gutterBottom
                                >
                                    Интегрируйте сервис агрегатора доставки через API и пользуйтесь собственной привычной системой оформления заказов

                                </Typography>
                                

                                <Button variant="contained" 
                                    color="primary" 
                                    disableElevation 
                                    onClick={(event) => handleLinkTo(event, 'registration-request/')}
                                >
                                    Подключить магазин
                                </Button>

                            </div>
                            
                        </Grid>

                    </Grid>
                </Container>
            </Paper>
            <div className={classes.bottom}> 
                <Container maxWidth='lg'>
                    <Grid container spacing={9} justifyContent='center'>
                        <Grid item md={3} lg={3} sm={12} align="left" border={1}>
                            <Box  className={classes.bottom1Items}>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    999
                                    </Typography>
                                    <Typography className={classes.pos} >
                                    <br />
                                    </Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">
                                        999.md
                                        <br />
                                        <br />
                                    </Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">
                                        business.999.md
                                        <br />
                                    </Typography>
                                    </Box>

                            

                        </Grid>
                        <Grid item md={3} lg={3} sm={12} align="left">
                            <Box  className={classes.bottom1Items} >
                                                                    <Typography variant="h5" component="h2" gutterBottom>
                                    Правила
                                    </Typography>
                                    <Typography className={classes.pos} >
                                    <br />
                                    </Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">
                                        Пользовательское соглашение
                                        <br />
                                        <br />
                                    </Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">
                                        Политика обработки данных
                                        <br />
                                    </Typography>
                            </Box>




                        </Grid>
                        <Grid item md={3} lg={3} sm={12} align="left">
                        <Box  className={classes.bottom1Items} >
                                                                <Typography variant="h5" component="h2" gutterBottom>
                                    Контакты
                                    </Typography>
                                    <Typography className={classes.pos} >
                                    <br />
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        component="p" 
                                        color="textSecondary"
                                        className={classes.footerIcon}
                                    >
                                        <PhoneIcon fontSize='small'/>&nbsp;
                                        <Box component='span'>+373 60 399 990</Box>
                                        <br />
                                        <br />
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        component="p" 
                                        color="textSecondary"
                                        className={classes.footerIcon}
                                    >
                                        <MailOutlineIcon fontSize='small' />&nbsp;
                                        <Box component='span'>delivery@999.md</Box>                                          
                                        <br />
                                    </Typography>
                        </Box>




                        </Grid>
                        <Grid item md={3} lg={3} sm={12} align="left">
                        <Box  className={classes.bottom1Items} >
                                                                <Typography variant="h5" component="h2" gutterBottom>
                                    Соцсети
                                    </Typography>
                                    <Typography className={classes.pos} >
                                    <br />
                                    </Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">
                                        <FacebookIcon/>
                                        <br />

                                        <br />
                                    </Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">
                                        <InstagramIcon/>
                                        <br />
                                    </Typography>
                        </Box>
                        </Grid>                        
                    </Grid>
                </Container>
                <Container maxWidth='lg'>
                    <Grid container 
                        justifyContent='center' 
                        alignItems='center' 
                        className={classes.bottomLogoBlock}>
                        <Grid item md={12} lg={7} sm={12} align="center">
                            <img src={BottomLogoSwiss} alt='' height='40px'/>
                            <img 
                                src={BottomLogoHelvetas} 
                                className={classes.logoOptim} 
                                alt='' height='32px'
                            />
                            <img 
                                src={BottomLogoOptim} 
                                className={classes.logoOptim} 
                                alt='' height='32px'
                            />                           
                        </Grid> 
                        <Grid item md={12} lg={5} sm={12} align="left">
                            <Typography component='span' className={classes.cooperativeText}>
                                Проект Delivery Service - Агрегатор Доставки, был реализован при поддержке Швейцарским Управлением по Развитию и Сотрудничеству (SDC) и HELVETAS Swiss Intercooperation в рамках проекта «Инновационные и Технологические возможности в Молдове».
                            </Typography>
                        </Grid>                      
                    </Grid>
                </Container>
                <Container maxWidth='lg'>
                <Grid container spacing={9} justifyContent='center'>
                        <Grid item md={2} lg={3} sm={12} align="center">
                            <IconButton color="secondary" aria-label="Profi" component="span"
                            onClick={() => window.open("https://profi.md/")} >
                                <img src={BottomLogoProfi} alt='' width="90%"/>
                            </IconButton>
                        </Grid>
                        <Grid item md={2} lg={2} sm={12} align="center">
                            <IconButton color="primary" aria-label="Joblist" component="span"
                            onClick={() => window.open("https://joblist.md/")} >
                                <img src={BottomLogoJoblist} alt='' width="90%"/>
                            </IconButton>
                        </Grid>
                        <Grid item md={2} lg={2} sm={12} align="center">
                            <IconButton color="primary" aria-label="Simpals" component="span"
                            onClick={() => window.open("https://simpals.com/")} >
                                <img src={BottomLogoSimpals} alt='' width="90%"/>
                            </IconButton>
                        </Grid>
                        <Grid item md={2} lg={2} sm={12} align="center">
                            <IconButton color="primary" aria-label="999" component="b"
                            onClick={() => window.open("https://999.md/")} >
                                <img src={BottomLogo999} alt='' width="50%"/>
                            </IconButton>
                        </Grid>
                        <Grid item md={2} lg={2} sm={12} align="center">
                            <IconButton color="primary" aria-label="Price" component="span"
                            onClick={() => window.open("https://price.md/")} >
                                <img src={BottomLogoPrice} alt='' width="70%"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Container>    
            </div>


        </main>
    )
}
