import React from 'react';

import { TextField, Grid, Typography, Divider, 
    InputLabel, FormControl, Select, Box, Button, Paper,
    TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, FormControlLabel, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChooseCity from '../global/ChooseCity';
import ChooseStreet from '../global/ChooseSrteet';

import { addShippingPoint } from '../../services/requests/ClientApi';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    mainBlockTitle: {
        left: '405px',
        top: '236px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23',
        marginTop: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
        // minWidth: 120,
    },
    calcBtnBlock: {
        '& > *': {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        //   textAlign: 'center'
        },
      },
    newOrderBnt:{
        width: '25%'
    }
}));
export default function AddNewSipingPoint() {
    const classes = useStyles();
    let navigate = useNavigate();
    const [state, setState] = React.useState({

        name: '',
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhones: '',
        city: {},
        street: {},
        building: '',
      });
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        msg: "Error"
    });
    const [visibleReturn, setVisibleReturn] = React.useState(false);
    const setCities = (cityInput) => {
        setState({
            ...state,
            city: cityInput,
            });
    }

    const setStreet = (streetInput) => {
        setState({
            ...state,
            street: streetInput,
            });
    };

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
          });
    };

    const addNewShipingPoint = () => {
        console.log("state", state)
        addShippingPoint(state)    
        .then(data => {
            setOpenSuccess(true);
            setVisibleReturn(true);
          })
        .catch(function (error) {
            console.log("error: ", error.response.data.detail); // => the response payload
            setError({
                status: true,
                msg:  "Error",
            });
          })
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSuccess(false);
        setError({
          status: false,
          msg: "Error"
      });
    };

    return (
        <div>
            <Typography
                    className={classes.mainBlockTitle}
                    component="h3"
                    variant='h4'
                    color="inherit"
                    gutterBottom
                >
                    Добавить адрес своего магазина
                </Typography>
            <div>

                <Grid container spacing={3}>
                    <Grid item md={12} lg={12} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                // className={classes.margin}
                                sx={{ width: 1 }}
                                label="Название"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="name"
                                name='name'
                                onChange={handleChange}
                                autoComplete="new-password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                // className={classes.margin}
                                sx={{ width: 1 }}
                                label="Имя"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="contactFirstName"
                                name='contactFirstName'
                                onChange={handleChange}
                                autoComplete="new-password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                // className={classes.margin}
                                sx={{ width: 1 }}
                                label="Фамилия"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="contactLastName"
                                name='contactLastName'
                                onChange={handleChange}
                                autoComplete="new-password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                // className={classes.margin}
                                sx={{ width: 1 }}
                                label="Email"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="contactEmail"
                                name='contactEmail'
                                onChange={handleChange}
                                autoComplete="new-password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                // className={classes.margin}
                                sx={{ width: 'auto' }}
                                label="Телефон"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="contactPhones"
                                name='contactPhones'
                                onChange={handleChange}
                                autoComplete="new-password"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} lg={4} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ChooseCity  language={'ru'} setParam={setCities} />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ChooseStreet cityId={state.city} setParam={setStreet} />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} lg={2} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                // className={classes.margin}
                                sx={{ width: 'auto' }}
                                label="Дом"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="building"
                                name='building'
                                onChange={handleChange}
                                autoComplete="new-password"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <div className={classes.calcBtnBlock}  align='center'>
                    {/* // TODO: Add  validation for button */}
                    <Button  
                        className={classes.newOrderBnt} 
                        variant="contained" color="primary" size='large' disableElevation
                        onClick={() => addNewShipingPoint()}
                        startIcon={<AddIcon />}
                        >
                        Добавить новую точку продаж
                    
                    </Button>
                </div>
                <div className={classes.calcBtnBlock} style={{ visibility: visibleReturn ? 'visible' : 'hidden'}} align='center' >
                    {/* // TODO: Add  validation for button */}
                    <Button  
                        // className={classes.newOrderBnt} 
                        variant="contained" color="primary" size='large' disableElevation
                        onClick={() => navigate(`/client/info`)}
                        // startIcon={<DetailsIcon />}
                        >
                        Вернуться в кабинет
                    
                    </Button>
                </div>
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                            Данные успешно добавлены.
                    </Alert>
                </Snackbar>
                <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                            {error.msg}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}
