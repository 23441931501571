import React from 'react'

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getStreets } from '../../services/requests/OpenApi';



export default function ChooseStreet(props) {

    const [streets, setStreets] = React.useState([]);

    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        if (props.cityId != null){
            getStreets(props.cityId).then(data => {
                setStreets(data)
            });
        } else { setStreets([]); setValue(""); setInputValue("");     }

        }, [props, setStreets]
    )

    const defaultProps = {
        options: streets,
        getOptionLabel: (option) => option.id,
      };
    
    return (
        <div>
            <Autocomplete
                // {...defaultProps}
                id="street"
                onChange={(event, newValue) => {
                    setValue(newValue);
                    props.setParam(newValue)
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={streets}
                autoHighlight
                getOptionLabel={(option) => `${option.streetType}. ${option.title}`}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.streetType}. {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => <TextField {...params} 
                variant="outlined" 
                name="street" 
                label="Выберите улицу"
                autoComplete="off"
                />}
            />
        </div>
    )
}
