import React from 'react'
import { TextField, Grid, Typography, Divider, 
    InputLabel, FormControl, Select, Box, Button, Paper,
    TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, FormControlLabel, Checkbox, IconButton, Radio, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import DetailsIcon from '@material-ui/icons/Details';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddIcon from '@material-ui/icons/Add';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import StoreIcon from '@material-ui/icons/Store';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import WidgetsIcon from '@material-ui/icons/Widgets';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import HeightIcon from '@material-ui/icons/Height';
import PanoramaHorizontalIcon from '@material-ui/icons/PanoramaHorizontal';
import PanoramaVerticalIcon from '@material-ui/icons/PanoramaVertical';




import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useNavigate } from "react-router-dom";
import { formatDate } from '../../services/TimeService';
import { getShippingPoint, getPrises } from '../../services/requests/ClientApi'

import ChooseCity from '../global/ChooseCity';
import { ControlCameraOutlined, LocalConvenienceStoreOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    mainBlockTitle: {
        left: '405px',
        top: '236px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
        // minWidth: 120,
    },
    calcBtnBlock: {
        '& > *': {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
        //   textAlign: 'center'
        },
      },
    newOrderBnt:{

        width: '25%',
        [theme.breakpoints.down("sm")]: {
            width: '75%',
          },
    }
}));


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        // prefix="см: "
      />
    );
}


function NumberFormatCustomWeight(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="кг: "
      />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};





export default function CalcNewOrder(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({

        recipientCity: '',
        senderCity: '',
        type: 'Cargo',

        deliveryMethodFrom: {
            fromDoor: true,
            fromOffice: false,
        },
        deliveryMethodTo: {
            toDoor: true,
            toOffice: true,
            toPostomat: false,
        },
        documentProps: {
            weight: 0.1,
            seatAmount: 1
        },
        cargoProps: [
            {
                weight: 0.1,
                length: 0,
                width: 0,
                height: 0,
            },
        ],
        selectedShipingPoint: {},
    });

    const [viewCost, setViewCost] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);


    React.useEffect(() => {
        setViewCost(false);
        
    }, [state]);


    const handleChangeType = (event) => {
        setState({
            ...state,
            type: event.target.value,
            documentProps: {
                weight: 0.1,
                seatAmount: 1
            },
            cargoProps: [
            {
                weight: 0.1,
                length: 0,
                width: 0,
                height: 0,
            },]
        });
      };

    const setRecipientCity = (cityInput) => {
        setState({
            ...state,
            recipientCity: cityInput,
            });
    };
    const setSenderCity = (cityInput) => {
        setState({
            ...state,
            senderCity: cityInput,
            });
    };

    
    const [costsTable, setCostsTable] = React.useState([]);
    
    function calcCosts(event){

        let weight = 0
        let length = 0
        let width = 0
        let height = 0
        let seatAmount = 1

        if (state.type === "Documents"){
            weight = state.documentProps.weight;
            seatAmount = state.documentProps.seatAmount;
        }

        if (state.type === "Cargo"){
            state.cargoProps.map((cargo) => {
                weight = weight + cargo.weight;
                length = length + cargo.length;
                width = width + cargo.width;
                height = height + cargo.height;
            });
            seatAmount = state.cargoProps.length;
        }

        let params = {
            cargoType: state.type,
            citySenderId: state.senderCity.id,
            cityRecipientId: state.recipientCity.id,
            citySenderTitle: state.senderCity.title,
            cityRecipientTitle: state.recipientCity.title,
            weight: weight,
            length: length,
            width: width,
            height: height,
            deliveryMethodTo: state.deliveryMethodTo,
            deliveryMethodFrom: state.deliveryMethodFrom,
            seatAmount: seatAmount,
        };
        console.log("params: ", params)
        setViewCost(false);
        setSpinner(true);

        getPrises(params)
        .then(function (response) {
            console.log("from getPrises success", response.data);
            setSpinner(false);
            setCostsTable(response.data);
            setViewCost(true);
        })
        .catch(function (error) {
            // TODO: processing error
            setSpinner(false);
            console.log("from getPrises errror", error);
        })

    };
    let navigate = useNavigate();
    function createOrder(deliveryCompany, cost, from, to, service_type_delivery_company) {

        let params = {
            deliveryCompany: deliveryCompany,
            cargoType: state.type,
            serviceType: service_type_delivery_company,
            selectedShipingPoint: state.selectedShipingPoint,
            cityRecipient: state.recipientCity,
            packageParams: state.type === 'Documents' ? state.documentProps : state.cargoProps,
            cost: cost,
            from: from,
            to: to,
        };
        props.setNewOrder(params);

        navigate(`/client/new-order/`);
        console.log("createOrder: ",params)
    }




    function choiceDeliveryMethodTo(metod) {
        setState((prev) => {
            return {
                ...prev, 
                deliveryMethodTo: {
                    ...prev.deliveryMethodTo,
                    [metod]: !prev.deliveryMethodTo[metod],
                }
            }
          });
    };

    function choiceDeliveryMethodFrom(metod) {
        setState((prev) => {
            return {
                ...prev, 
                deliveryMethodFrom: {
                    ...prev.deliveryMethodFrom,
                    [metod]: !prev.deliveryMethodFrom[metod],
                }
            }
          });
    };

    function setDocsWeight(weight) {
        console.log("setParams def", weight);
        setState({
            ...state,
            documentProps: {
                ...state.documentProps,
                weight: weight
            },
        });
    }

    function setDocsAmount(number) {
        console.log("setParams def2", number);
        setState({
            ...state,
            documentProps: {
                ...state.documentProps,
                seatAmount: number
            },
        });
    }


    console.log("state: ", state);


    return (
        <div>
            <Box>
                <Typography
                    className={classes.mainBlockTitle}
                    component="h3"
                    variant='h5'
                    color="inherit"
                    gutterBottom
                >
                    Новый заказ
                </Typography>
                <Grid container spacing={3}>
                    <Grid item md={6} lg={6} sm={12} xs={12} align='left'>
                        <Typography
                            
                            component="p"
                            variant='h5'
                            color="inherit"
                            gutterBottom
                        >
                            Откуда:
                        </Typography>
                        <Divider light />


                        <Grid container spacing={3} align='center'>
                            <Grid item md={6} lg={6}   sm={6} xs={6} align='center'>
                                <FormControlLabel
                                    control={<Checkbox icon={<HomeWorkOutlinedIcon />} 
                                    checkedIcon={<HomeWorkIcon />}
                                    checked={state.deliveryMethodFrom.fromDoor}
                                    onClick={() => choiceDeliveryMethodFrom("fromDoor")} 
                                    name="fromDoor" />}
                                    label="От двери"
                                />
                            </Grid>
                            <Grid item md={6} lg={6}  sm={6} xs={6} align='center'>
                                <FormControlLabel disabled
                                    control={<Checkbox icon={<StoreOutlinedIcon />} 
                                    checkedIcon={<StoreIcon />} 
                                    checked={state.deliveryMethodFrom.fromOffice}
                                    onClick={() => choiceDeliveryMethodFrom("fromOffice")} 
                                    name="fromOffice" />}
                                    label="От отделения"
                                />
                            </Grid>


                        </Grid>
                        {state.deliveryMethodFrom.fromDoor &&
                            <ChooseShippingPoint  senderCity={state.senderCity} setState={setState} />}


                        {!state.deliveryMethodFrom.fromDoor  &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <ChooseCity  language={'ru'} setParam={setSenderCity} />
                            </FormControl>
                            }
                    </Grid>
                    <Grid item md={5} lg={6} sm={12} xs={12} align='left'>
                    <Typography
                            
                            component="p"
                            variant='h5'
                            color="inherit"
                            gutterBottom
                        >
                            Куда:
                        </Typography>
                        <Divider light />
                    <Grid container spacing={3} align='center'>
                    <Grid item md={6} lg={6}  sm={6} xs={6} align='center'>
                        <FormControlLabel
                            control={<Checkbox icon={<HomeWorkOutlinedIcon />} 
                            checkedIcon={<HomeWorkIcon />} 
                            checked={state.deliveryMethodTo.toDoor}
                            onClick={() => choiceDeliveryMethodTo("toDoor")} 
                            name="toDoor" />}
                            label="До двери"
                        />
                    </Grid>
                    <Grid item md={6} lg={6}  sm={6} xs={6} align='center'>
                        <FormControlLabel
                            control={<Checkbox icon={<StoreOutlinedIcon />} 
                            checkedIcon={<StoreIcon />} 
                            checked={state.deliveryMethodTo.toOffice}
                            onClick={() => choiceDeliveryMethodTo("toOffice")} 
                            name="toOffice" />}
                            label="До отделения"
                        />
                    </Grid>
                    <Grid item md={12} lg={12}  sm={12} xs={12} align='center'>
                        <FormControlLabel disabled
                            control={<Checkbox icon={<WidgetsOutlinedIcon />} 
                            checkedIcon={<WidgetsIcon />} 
                            onClick={() => choiceDeliveryMethodTo("toPostomat")} 
                            name="toPostomat" />}
                            label="До постамата"
                        />
                    </Grid>

                </Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ChooseCity  language={'ru'} setParam={setRecipientCity} />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box>                
                <Typography
                    className={classes.mainBlockTitle}
                    component="h3"
                    variant='h5'
                    color="inherit"
                    gutterBottom
                >
                    О доставке
                </Typography>

                <div>
                    <FormControlLabel value="Cargo" control={<Radio
                        checked={state.type === 'Cargo'}
                        onChange={handleChangeType}
                        value="Cargo"
                        name="radio-button-cargo"
                        inputProps={{ 'aria-label': 'Cargo' }}
                    />} label="Cargo"  />
                    <FormControlLabel value="Documents" control={<Radio
                        checked={state.type === 'Documents'}
                        onChange={handleChangeType}
                        value="Documents"
                        name="radio-button-documents"
                        inputProps={{ 'aria-label': 'Documents' }}
                        
                    />} label="Documents"  />
                </div>

                {state.type === "Cargo" && 
                    <CargoCalc cargoProps={state.cargoProps} setState={setState} />
                }

                {state.type === "Documents" && 
                    <CalcDocuments 
                        setDocsWeight={setDocsWeight} 
                        setDocsAmount={setDocsAmount}
                        documentProps={state.documentProps}
                    />
                }




            </Box>
            <Box sm={12} xs={12}>
                
                <div className={classes.calcBtnBlock} align='center' sm={12} xs={12}>
                    {/* // TODO: Add  validation for button */}
                    <Button  sm={12} xs={12}
                        className={classes.newOrderBnt} 
                        variant="contained" color="primary" 
                        // size='large' 
                        // disableElevation
                        onClick={(event) => calcCosts(event)}
                        startIcon={<DetailsIcon />}
                        >
                        <b>Рассчитать</b>
                    
                    </Button>
                </div>

            </Box>
            <div style={{ visibility: spinner ? 'visible' : 'hidden'}} align='center'  >
                <CircularProgress />
            </div>
            <div style={{ visibility: viewCost ? 'visible' : 'hidden' }}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Курьерская компания</TableCell>
                            <TableCell>Тип доставки</TableCell>
                            <TableCell align="right">Стоимость</TableCell>
                            <TableCell align="right">Выбрать</TableCell>
                        </TableRow>
                        </TableHead>
                        {/* diliveryman, arriveDate, cost, id */}
                        <TableBody> 
                        { costsTable.map((row) => (
                            <TableRow key={row.company}>
                                <TableCell>{row.company_title}</TableCell>
                                <TableCell>{row.service_type}</TableCell>
                                <TableCell align="right">{row.cost}</TableCell>
                                <TableCell align="right">
                                    { row.to === "door" &&  
                                    <Button 
                                        variant="contained" 
                                        onClick={ () => createOrder(row.company, row.cost, row.from, row.to, row.service_type_delivery_company) }
                                        color="primary">
                                        Оформить доставку
                                    </Button>}
                                    { row.to === "office" &&  
                                    <Button disabled
                                        variant="contained" 
                                        onClick={ () => createOrder(row.company, row.cost, row.from, row.to, row.service_type_delivery_company) }
                                        color="primary">
                                        Оформить доставку
                                    </Button>}
                                    { row.company === "fan_courier" &&  
                                    <Button
                                        variant="contained" 
                                        onClick={ () => createOrder(row.company, row.cost, row.from, row.to, row.service_type_delivery_company) }
                                        color="primary">
                                        Оформить доставку
                                    </Button>}

                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

            </div>


        </div>
    )
}


function CargoCalc(props){
    const classes = useStyles();
    let cargo = props.cargoProps;

    function addCargo(){
        // let cargo = props.cargoProps;
        cargo.push({
            weight: 0.1,
            length: 0,
            width: 0,
            height: 0,
        })
        props.setState((prev) => {
            return {
                ...prev, 
            cargoProps: cargo
            }
        })
    }

    function delCargo(index){
        console.log("del : ", index);
        // delete cargo[index];
        cargo.splice(index,1);
        props.setState((prev) => {
            return {
                ...prev, 
            cargoProps: cargo
            }
        })
    }

    function handleChange(event, index){
        let changebleCargo = cargo[index];
        changebleCargo[event.target.name] = parseFloat(event.target.value);
        cargo[index] = changebleCargo
        console.log(cargo);
        props.setState((prev) => {
            return {
                ...prev, 
            cargoProps: cargo
            }
        })
    }


    return(
    <div>   


                {cargo.map((cargo, index) => {
                    return(
                        <div><Grid 
                        container spacing={2}   
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item md={2} lg={2} sm={12} xs={12}>
                            <FormControl variant="outlined" 
                            className={classes.formControl}
                            >
                                <TextField
            
                                    label="Вес"
                                    value={props.cargoProps[index].weight}
                                    onChange={(event) => handleChange(event, index)}
                                    name="weight"
                                    id="formatted-numberformat-input"
                                    variant="outlined"
                                    InputProps={{
                                        'aria-label': 'weight',
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                              <FitnessCenterIcon />
                                            </InputAdornment>
                                          ),
                                        endAdornment: (<InputAdornment position="end">Kg</InputAdornment>)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} lg={3} sm={12} xs={12} >
                            <FormControl variant="outlined" 
                            className={classes.formControl}
                            >
                            
                                <TextField
                                    label="Длина"
                                    value={props.cargoProps[index].length}
                                    onChange={(event) => handleChange(event, index)}
                                    name="length"
                                    id="formatted-numberformat-input"
                                    variant="outlined"
                                    InputProps={{
                                        'aria-label': 'weight',
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                              <PanoramaHorizontalIcon />
                                            </InputAdornment>
                                          ),
                                        endAdornment: (<InputAdornment position="end">cm</InputAdornment>)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    <Grid item md={3} lg={3} sm={12} xs={12}>
                            <FormControl variant="outlined" 
                            className={classes.formControl}
                            >
                                <TextField
            
                                    label="Ширина"
                                    value={props.cargoProps[index].width}
                                    onChange={(event) => handleChange(event, index)}
                                    name="width"
                                    id="formatted-numberformat-input"
                                    variant="outlined"
                                    InputProps={{
                                        'aria-label': 'weight',
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                              <PanoramaVerticalIcon />
                                            </InputAdornment>
                                          ),
                                        endAdornment: (<InputAdornment position="end">cm</InputAdornment>)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} lg={3} sm={12} xs={12}>
                            <FormControl variant="outlined" 
                            className={classes.formControl}
                            >
                                <TextField
            
                                    label="Высота"
                                    value={props.cargoProps[index].height}
                                    onChange={(event) => handleChange(event, index)}
                                    name="height"
                                    id="formatted-numberformat-input"
                                    variant="outlined"
                                    InputProps={{
                                        'aria-label': 'weight',
                                        inputComponent: NumberFormatCustom,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                              <HeightIcon />
                                            </InputAdornment>
                                          ),
                                        endAdornment: (<InputAdornment position="end">cm</InputAdornment>)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1} lg={1} sm={12} xs={12} align="center">
            
                        
                            <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            size="small"
                            onClick={addCargo}
                            >
                                <AddIcon />
                            </IconButton>
            
                            <IconButton 
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            size="small"
                            onClick={() => delCargo(index)}
                            style={{ visibility: props.cargoProps.length > 1 ? 'visible' : 'hidden'}}
                            >
                                <DeleteForeverOutlinedIcon />
                            </IconButton>
                        </Grid>
            
                    </Grid>
                    </div>
                    );
                })}
        {/*  */}
    </div>
    )
}


function CalcDocuments(props){
    const classes = useStyles();
    console.log("enter in def")
    function handleChangeWeightParam(event){
        props.setDocsWeight(event.target.value)
    }

    function handleChangeAmountParam(event){
        props.setDocsAmount(event.target.value)
    }

    return(
        <div>
            <Grid 
                container spacing={2}   
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Grid item md={4} lg={4} sm={12} xs={12}>
                    <FormControl variant="outlined" 
                    className={classes.formControl}
                    >
                        <TextField

                            label="Вес"
                            value={props.documentProps.weight}
                            onChange={handleChangeWeightParam}
                            name="weight"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                'aria-label': 'weight',
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                      <FitnessCenterIcon />
                                    </InputAdornment>
                                  ),
                                endAdornment: (<InputAdornment position="end">Kg</InputAdornment>)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} lg={3} sm={12} xs={12}>
                    <FormControl variant="outlined" 
                    className={classes.formControl}
                    >
                        <TextField

                            label="Количество мест"
                            value={props.documentProps.seatAmount}
                            onChange={handleChangeAmountParam}
                            name="seatAmount"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                      <FormatListNumberedOutlinedIcon />
                                    </InputAdornment>
                                  ),
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
}

function ChooseShippingPoint(props){
    const classes = useStyles();

    const [shipingPoints, setShipingPoints] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const choosedCity = props.senderCity === '' ? props.senderCity : props.senderCity.title;
    React.useEffect(() => {

        getShippingPoint().then(data => {
            setShipingPoints(data.shippingPoints);
            setLoading(false);
        });
        }, []);

    const handleChangeShipingPoint = (event, newValue) => {
        // console.log("target.value", event.target.value)
        props.setState((prev) => {
            console.log(newValue)
            if (event.target.value === undefined){
                return {
                    ...prev, 
                    selectedShipingPoint: {},
                    senderCity: '',
                }
            } else {
                return {
                    ...prev, 
                    selectedShipingPoint: newValue,
                    senderCity: {
                        id: newValue.delivery_company_credentials.nova_posta.city_id,
                        title: newValue.city,
                    }
                }
            }
        })
    };


    return(
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
            <Autocomplete
                // {...defaultProps}
                id="shippingPoints"
                onChange={(event, newValue) => {
                    handleChangeShipingPoint(event, newValue);
                    // props.setParam(newValue)
                }}
                // inputValue={inputValue}
                // onInputChange={(event, newInputValue) => {
                //     setInputValue(newInputValue);
                // }}
                options={shipingPoints}
                autoHighlight
                getOptionLabel={(option) => option.name}

                loading={loading}

                renderOption={(option) => (
                  <React.Fragment>
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => 
                    <TextField {...params} 
                        variant="outlined" 
                        name="city" 
                        label="Выберите отделение"
                        autoComplete="off"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}

                />}
            />
            </FormControl>
            
            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    id="outlined-multiline-flexible"
                    label="Город отправления"
                    

                    value={choosedCity} 

                    
                    // onChange={handleChange}
                    // variant="outlined"
                    InputProps={{
                        readOnly: true,
                        }}
                />
            </FormControl>
        </div>
    )
}


