import React from 'react'

import { Grid, Typography, 
    FormControl, TextField, Button,     
    Checkbox } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {
    withStyles,
    makeStyles,
    } from '@material-ui/core/styles';

import ChooseCity from './global/ChooseCity';
import { saveNewRegistrationRequest } from '../services/requests/OpenApi'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    registrationRequest: {
        padding: theme.spacing(10)
    },
    mainBlockTitle: {
        left: '405px',
        top: '236px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23',
        marginTop: theme.spacing(3),
        margin: theme.spacing(1),
    },
    mainBlockTitle2: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23',
        margin: theme.spacing(1),
    },
    mainBlockTitle3: {
        fontStyle: 'normal',
        // fontWeight: 'bold',
        color: '#4D4E54',
        marginBottom: theme.spacing(3),
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    sendBtnBlock: {
        '& > *': {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            },
    },
    sendBtn: {
        width: '40%'
    },
    inputForm: {
        padding: theme.spacing(2),
    }
}));

const ValidationTextField = withStyles({
    root: {
      '& input:valid + fieldset': {
        borderColor: 'green',
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 2,
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important', // override inline-style
      },
    },
  })(TextField);

export default function RegistrationRequest() {
    const classes = useStyles();
    const [state, setState] = React.useState({

        companyName: '',
        stakeholderName: '',
        phone: '+373',
        email: '',
        city: '',
        address: 'Chisinau, ',
        agreement: false

      });
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        msg: "Error"
    });
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
          });
      };

    const setCities = (cityInput) => {
        setState({
            ...state,
            city: cityInput.title,
          });
    }

    const handleChangeAgreement = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const sendRegRequest = (event) => {
        saveNewRegistrationRequest(state)
        .then(function (response) {
            // console.log("from def success", response);
            setOpenSuccess(true);
          })
        .catch(function (error) {
            if( error.response ){
                // console.log(error.response.data.detail[0].msg); // => the response payload 
                setError({
                    status: true,
                    msg:  error.response.data.detail[0].msg
                });
            }
            


          })
    };

    function dynamicFormValidation() {
      if (
          state.companyName.length > 0 &&
          state.stakeholderName.length > 0 &&
          state.phone.length > 0 &&
          state.email.length > 0 &&
          state.city.length != '' &&
          state.address.length > 0 &&
          state.agreement
      ) {
          return true
      }
      return false
    }

    let formValid = dynamicFormValidation();
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSuccess(false);
      setError({
        status: false,
        msg: "Error"
    });
    };
    
    return (
        <div className={classes.registrationRequest}>
            
            <Typography
                className={classes.mainBlockTitle}
                component="h1"
                variant='h3'
                color="inherit"
                gutterBottom
            >
                Заполните заявку
            </Typography>


            <Typography
                className={classes.mainBlockTitle3}
                component="h5"
                variant='body1'
                color="inherit"
                gutterBottom
            >
                Мы свяжемся с вами чтоы обсудить детали.
            </Typography>

            <form>
            <Grid container spacing={3}>
                    <Grid item md={6} lg={6}  sm={12}>

                        <FormControl variant="outlined" className={classes.formControl}>
                            <ValidationTextField
                                className={classes.margin}
                                label="Название компании"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="companyName"
                                name='companyName'
                                onChange={handleChange}
                                autoComplete="new-password"
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ValidationTextField
                                className={classes.margin}
                                label="Номер телефона"
                                required
                                variant="outlined"
                                defaultValue="+373"
                                id="phone"
                                name='phone'
                                onChange={handleChange}
                            />
                        </FormControl>                        
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ChooseCity  language={'RO'} setParam={setCities} />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} align='left'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ValidationTextField
                                className={classes.margin}
                                label="Имя и фамилия"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="name"
                                name='stakeholderName'
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ValidationTextField
                                className={classes.margin}
                                label="Электронный адрес"
                                required
                                variant="outlined"
                                defaultValue=""
                                id="email"
                                name='email'
                                onChange={handleChange}
                            />
                        </FormControl>                        
                        <FormControl variant="outlined" className={classes.formControl}>
                            <ValidationTextField
                                className={classes.margin}
                                label="Адрес компании"
                                required
                                variant="outlined"
                                defaultValue='Chisinau, '
                                id="address"
                                name='address'
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
            <div>
                <Grid container spacing={3}>
                    <Grid item md={1} lg={1}  sm={1} align='left'>
                        <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={state.agreement} onChange={handleChangeAgreement} name="agreement"
                        />
                    </Grid>
                    <Grid item md={11} lg={11} sm={11} align='left'>
                        <Typography
                            className={classes.mainBlockTitle2}
                            component="h6"
                            variant='h6'
                            color="inherit"
                            gutterBottom
                        >
                            Указывая свои персональные данные в полях заявки, вы соглашаетесь на их обработку.
                        </Typography>
                        <Typography
                            className={classes.mainBlockTitle3}
                            component="h5"
                            variant='body2'
                            color="inherit"
                            gutterBottom
                        >
                            Данные обрабатываются на условиях Политики конфиденциальности для целей, указанных в анкете, и хранятся в течение 3 лет после достижения указанной цели.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.sendBtnBlock} align='center'>
                <Button  
                    className={classes.sendBtn} 
                    variant="contained" color="primary" size='large' disableElevation
                    onClick={() => sendRegRequest()}
                    disabled={!formValid}
                    >
                    Отправить
                
                </Button>
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                            Заявка успешно зарегистрирована. Наш менеджер с вами свяжется
                    </Alert>
                </Snackbar>
                <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                            {error.msg}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}
