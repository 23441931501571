import React from 'react'
import { useParams } from "react-router-dom";
import { getOrderById } from '../../services/requests/ClientApi'
import { TextField, Grid, Typography, Divider, 
    InputLabel, FormControl, Select, Box, Button, Paper,
    TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, FormControlLabel, Checkbox } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import NovaPostaLogo from '../../assets/partners/image9.png';
import CourierRapidLogo from '../../assets/partners/logoCR.png';

import { makeStyles } from '@material-ui/core/styles';

import { formatDate } from '../../services/TimeService';




const useStyles = makeStyles((theme) => ({

    formControl: {
        margin: theme.spacing(1),
        width: '100%'
        // minWidth: 120,
    },

}));

function DeliveryCompanyLogo(props){
    if (props.props == "nova_posta"){
        return (
            <div>
                <img src={NovaPostaLogo} width="30%"/>
            </div>
        )
    };
    if (props.props == "courier_rapid"){
        return (
            <div>
                <img src={CourierRapidLogo} width="40%"/>
            </div>
        )
    };

    return (
        <div>
            logo Delivery Company
        </div>
    )

}



export default function OrderInfo() {
    let params = useParams();
    const classes = useStyles();
    const [order, setOrder] = React.useState({id: params.orderId});
    const [isWaiting, setIsWaiting] = React.useState(true);
    
    React.useEffect(() => {
        getOrderById(params.orderId)
        .then(function (response) {
            // console.log("from get order by id success", response.order);
            setOrder(response.order);
            setIsWaiting(false);
        })
        .catch(function (error) {
            // TODO: processing error

            console.log("from get order by id errror", error);
        })
    }, []);


    if (isWaiting) {
        return (
            <div align='center'>
                <CircularProgress />
            </div>
        )
    }
    return (
        <div>
            <Typography
                    className={classes.mainBlockTitle}
                    component="h3"
                    variant='h4'
                    color="inherit"
                    gutterBottom
                >
                    Информация о заказе №: {order.id}
                </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            < DeliveryCompanyLogo props={order.delivery_company}/> 
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                                <Typography variant="h3" color="textSecondary" component="p">
                                    MLD: { order.cost }
                                </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} align='center'>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                    label="Описание"
                                    value={order.cargo_description}
                                    // onChange={handleChange}
                                    name="cargo_description"
                                    id="formatted-numberformat-input"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Дата создания"
                                value={ formatDate(order.created_date) }
                                // onChange={handleChange}
                                name="created_date"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Тип"
                                value={ order.cargo_type }
                                // onChange={handleChange}
                                name="cargo_type"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Заявленная стоимость"
                                value={ order.declared_cost }
                                // onChange={handleChange}
                                name="declared_cost"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

            </Box>
            <Box>
                <Typography
                    className={classes.mainBlockTitle}
                    component="h3"
                    variant='h4'
                    color="inherit"
                    gutterBottom
                >
                    Инфо отправителя
                </Typography>

                <Grid container spacing={2}>

                <Grid item md={5} lg={5} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Город"
                                value={order.sender.city}
                                // onChange={handleChange}
                                name="senderCity"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={5} lg={5} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Улица"
                                value={order.sender.street}
                                // onChange={handleChange}
                                name="senderStreet"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} lg={2} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Дом"
                                value={order.sender.building}
                                // onChange={handleChange}
                                name="senderBuilding"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}

                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={12} lg={12} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Фамилия и Имя"
                                value={order.sender.contact_first_name + " " + order.sender.contact_last_name}
                                // onChange={handleChange}
                                name="senderName"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}

                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>

                    <Grid item md={6} lg={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Телефон"
                                value={order.sender.contact_phones}
                                // onChange={handleChange}
                                name="senderPhone"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}

                            />
                        </FormControl>
                    </Grid>


                    <Grid item md={6} lg={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Email"
                                value={order.sender.contact_email}
                                // onChange={handleChange}
                                name="senderEmail"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}

                            />
                        </FormControl>
                    </Grid>
                </Grid>


            </Box>
            <Box>
                <Typography
                    className={classes.mainBlockTitle}
                    component="h3"
                    variant='h4'
                    color="inherit"
                    gutterBottom
                >
                    Инфо получателя
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={5} lg={5} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Город"
                                value={order.recipient.city}
                                // onChange={handleChange}
                                name="recipientCity"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}

                            />
                        </FormControl>
                    </Grid>


                    <Grid item md={5} lg={5} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                                label="Улица"
                                value={order.recipient.street}
                                // onChange={handleChange}
                                name="recipientStereet"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} lg={2} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Дом"
                                value={order.recipient.building}
                                // onChange={handleChange}
                                name="recipientBuilding"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Имя"
                                value={order.recipient.first_name}
                                // onChange={handleChange}
                                name="recipientFirstName"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Фамилия"
                                value={order.recipient.last_name}
                                // onChange={handleChange}
                                name="recipientLastName"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Телефон"
                                value={order.recipient.phone}
                                // onChange={handleChange}
                                name="recipientPhone"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>


                    <Grid item md={6} lg={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Email"
                                value={order.recipient.email}
                                // onChange={handleChange}
                                name="recipientEmail"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
