import React from 'react'

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCities } from '../../services/requests/OpenApi';



export default function ChooseCity(props) {

    const language = props.language;
    const [cities, setCities] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    React.useEffect(
        () => {
            getCities()
            .then(function (data) {
                setCities(data);
                setLoading(false);
            });
        }, []
    )

    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');

    const defaultProps = {
        options: cities,
        getOptionLabel: (option) => option.id,
      };
    
    return (
        <div>
            <Autocomplete
                // {...defaultProps}
                id="cities"
                onChange={(event, newValue) => {
                    setValue(newValue);
                    props.setParam(newValue)
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={cities}
                autoHighlight
                getOptionLabel={(option) => option.title}

                loading={loading}

                renderOption={(option) => (
                  <React.Fragment>
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => 
                    <TextField {...params} 
                        variant="outlined" 
                        name="city" 
                        label="Выберите город"
                        autoComplete="off"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}

                />}
            />
        </div>
    )
}
