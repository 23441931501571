import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import Logo from '../assets/999Delivery.svg';
import Container from '@material-ui/core/Container';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { isAuthClient } from '../services/requests/ClientApi';
import Cookies from 'js-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    height: '76px',
    width: '162px',
    alignItems: 'center',
    justifyContent:  'center',

  },
  appBar: {
    marginTop: '35px',
    backgroundColor: '#313238',
    height: '76px',
    position: 'absolute',
  },
  logoImage: {
    height: '36px',
    top: '50%',
  },
  logoBox: {
    top: '50%',
  },  
  title: {
    flexGrow: 1,
  },

  privateOfficeBtn:{
    color: "#acb44e",
    borderColor: '#acb44e',
  },

}));

export default function TopBar() {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(false);

  let navigate = useNavigate();

  const [lang, setlang] = React.useState('RU');

  React.useEffect(() => {
    if (Cookies.get('simpalsid.auth')) {
      // console.log("Cookies +")
      setAuth(true)
    } else {
      // console.log("Cookies -")
      setAuth(false)
    }
  }, [])

  const [error, setError] = React.useState({
    status: false,
    msg: "Error"
  });

  const handleAuth = (event) => {
    isAuthClient()
    .then(data => {
      navigate(`client`);
    })
    .catch(function(error){
      // console.log("error: ", error.response.data.detail);
      setError({
        status: true,
        msg:  error.response.data.detail,
    });
    })
  };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setError({
          status: false,
          msg: "Error"
      });
    };


  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
          <Container>
        <Toolbar>
          <IconButton className={classes.menuButton} color="inherit" aria-label="menu">
            <Link to="/">
              <div className={classes.logoBox} >
                <img className={classes.logoImage} src={Logo}/>
              </div>
            </Link>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            
          </Typography>
          
            <div style={{ visibility: auth ? 'visible' : 'hidden'}}>
                <Button 
                  variant="outlined"
                  className={classes.privateOfficeBtn}
                  onClick={handleAuth}
                >
                  <b>Личный кабинет</b>
                </Button>
            </div>
          
        </Toolbar>
        </Container>
      </AppBar>
      <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
                {error.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
