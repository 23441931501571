import axios from "axios";

// for local development
// const prefix = "http://0.0.0.0:8000/api/open/";


const prefix = process.env.NODE_ENV === "development" ? "http://0.0.0.0:8000/api/open/" : "/api/open/";

const basePath = `${prefix}`

// POST save in systen new registration request
export function saveNewRegistrationRequest(data){
    // post
    return axios.post(`${basePath}reg-request`, data)
};

// GET recive possible languages
export function getLanguages(){
    const cities = axios.get(`${basePath}languages`)
        .then(response => response.data.cities)
    return cities;
};

// GET recive possible cities
export function getCities () {
    const cities = axios.get(`${basePath}cities`)
        .then(response => response.data.cities)
    return cities;
};


// GET recive possible streets by city id
export function getStreets (cityId) {
    const streets = axios.get(`${basePath}street?city_id=${cityId.id}`)
        .then(response => response.data.streets)
    return streets;
};

