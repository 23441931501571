import React from 'react'

export default function Dashboard() {
    // require('dotenv').config();

    

    console.log(process.env.NODE_ENV);
    console.log(process.env.REACT_APP_DS_KEY);
    console.log(process.env.REACT_APP_DB_HOST);
    console.log(process.env.APP_KEY);
    return (
        <div>
            <p>Deliverymans - ...X...</p>
            <p>Shops - ...X...</p>
            <p>Orders - ...X...</p>
        </div>
    )
}
