import './App.css';
import TopBar from './components/TopBar';
import LandingMain from './components/LandingMain';
import AdminPage from './components/AdminPage';
import RegistrationRequest from './components/RegistrationRequest';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import ClientOffice from './components/ClientOffice';
import SimpalsID from './components/SimpalsID';

function App() {

  return (
    <div id="app">
      <Router>
        <div>
          
          <TopBar />

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Routes>
            <Route path="/" element={<LandingMain />} />
            <Route path="admin/*" element={<AdminPage />} />
            <Route path="client/*" element={<ClientOffice />} />
            <Route path="registration-request/*" element={<RegistrationRequest />} />
          </Routes>
        </div>
      </Router>
      
    </div>
  );
}

export default App;


function Admin() {
  return <h2>Admin</h2>;
}

function Client() {
  return <h2>Client</h2>;
}