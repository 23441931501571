import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ClientDashboard from './client/ClientDashboard';
import NewOrder from './client/NewOrder';
import CalcNewOrder from './client/CalcNewOrder';
import ClientInfo from './client/ClientInfo';
import OrderInfo from './client/OrderInfo';

import { Box, Drawer, Hidden } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";

import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import {
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
  } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import AddNewSipingPoint from './client/AddNewSipingPoint';
import AddNewDeliveryCompany from './client/AddNewDeliveryCompany';


const useStyles = makeStyles((theme) => ({
    clientsOffice: {

    },
    clientsMenu: {

    },
    cabinetContent: {
        width: "100%",
        minHeight: "100vh",
        height: "auto",
        background: "#efefef",
        boxSizing: "border-box",
        marginTop: "40px",
        padding: "100px 24px 24px 270px",
        [theme.breakpoints.down("sm")]: {
          padding: "100px 24px 24px 24px",
        },
    },
    //sidenav
    drawerPaper: {
        width: "250px",
        marginTop: "100px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "0px",
        },
        backgroundColor: '#313238',
    },
    navlink: {
        color: theme.palette.grey[50],
        transition: "0.8s",
        "&:hover  div": {
          color: theme.palette.grey[50],
        },
      },
    navButton: { width: "100%", textTransform: "capitalize" },
      selectedNav: {
        color: `${theme.palette.grey[50]} !important`,
        fontWeight: " bolder",
        " & div": {
          color: `${theme.palette.grey[50]} !important`,
        },
    },
}));

export default function ClientOffice({
    mobileOpen,
    handleDrawerClose,
    handleDrawerToggle,
  }) {
    const classes = useStyles();

    const [newOrder, setNewOrder] = React.useState({});
    return (
        <div className={classes.clientsOffice}>
            <div className={classes.clientsMenu}>
                <nav className={classes.drawer} aria-label='mailbox folders'>
                    {/* Hidden on bigger Size  */}
                    <Hidden mdUp implementation='css'>
                        <Drawer
                            variant='temporary'
                            anchor={"left"}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                            paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                            }}>
                            <SidenavData handleDrawerClose={handleDrawerClose} />
                        </Drawer>
                    </Hidden>
                    {/* visible on screen greater than 600px */}
                    <Hidden smDown implementation='css'>
                        <Drawer
                            classes={{
                            paper: classes.drawerPaper,
                            }}
                            variant='permanent'
                            open>
                            <SidenavData handleDrawerClose={handleDrawerClose} />
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
            <Box className={classes.cabinetContent}>
                <Routes>
                        <Route path="/" element={<ClientDashboard />} />
                        <Route path="info" element={<ClientInfo />} />
                        <Route path="new-order" element={<NewOrder newOrder={ newOrder }/>} />
                        <Route path="calc-new-order" element={<CalcNewOrder setNewOrder={setNewOrder} />} />
                        <Route path="order" element={<OrderInfo />} >
                          <Route path=":orderId" element={<OrderInfo />} />
                        </Route>
                        <Route path="new-point" element={<AddNewSipingPoint />} />
                        <Route path="new-delivery-company" element={<AddNewDeliveryCompany />} />
                </Routes>
            </Box>
        </div>

    )
}

function SidenavData({ handleDrawerClose }) {
    const classes = useStyles();
    const listItemData = [
      { label: "Dashboard", link: "/client", icon: <DashboardIcon /> },
      { label: "Client Info", link: "./info", icon: <SupervisorAccountIcon /> },
    //   { label: "Shop", link: "./shops", icon: <StoreIcon /> },
    //   { label: "Orders", link: "./orders", icon: <ViewListIcon /> },
    ];
  
    return (
      <List>
        {listItemData.map((item, i) => (
          <Button
            size='small'
            onClick={() => handleDrawerClose()}
            className={classes.navButton}>
            <ListItem
              exact
              key={i}
              component={NavLink}
              to={item.link}
              className={classes.navlink}
              activeClassName={classes.selectedNav}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </ListItem>
          </Button>
        ))}
      </List>
    );
  }