import React from 'react'
import { TextField, Grid, Typography, Divider, 
    InputLabel, FormControl, Select, Box, Button, Paper,
    TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, FormControlLabel, IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddIcon from '@material-ui/icons/Add';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import HeightIcon from '@material-ui/icons/Height';
import PanoramaHorizontalIcon from '@material-ui/icons/PanoramaHorizontal';
import PanoramaVerticalIcon from '@material-ui/icons/PanoramaVertical';

import DetailsIcon from '@material-ui/icons/Details';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import StoreIcon from '@material-ui/icons/Store';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createNewDeliveryOrder } from '../../services/requests/ClientApi'

import NovaPostaLogo from '../../assets/partners/image9.png';
import CourierRapidLogo from '../../assets/partners/logoCR.png';
import FanCourierLogo from '../../assets/partners/logoFC.png';

import ChooseStreet from '../global/ChooseSrteet';

import { useNavigate } from "react-router-dom";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    mainBlockTitle: {
        left: '405px',
        top: '236px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23',
        marginTop: theme.spacing(6),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
        // minWidth: 120,
    },
    calcBtnBlock: {
        '& > *': {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
        //   textAlign: 'center'
        },
      },
    newOrderBnt:{
        width: '25%'
    }
}));


function createData(diliveryman, arriveDate, cost, id) {
    return { diliveryman, arriveDate, cost, id };
}
  
const rows = [
    createData('Posta Moldovei', '2022-02-02 15:23:09.329490', 200, 24),
    createData('Nova Posta', '2022-02-06 15:23:09.329490', 120, 37),
    createData('Nova Posta', '2022-01-26 15:23:09.329490', 180, 24),
    createData('Nova Posta', '2022-01-31 15:23:09.329490', 150, 67),
    createData('Posta Moldovei', '2022-02-15 15:23:09.329490', 90, 49),
];

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="см: "
      />
    );
}

function NumberFormatCustomCost(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="MLD: "
      />
    );
}

function NumberFormatCustomWeight(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="кг: "
      />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function DeliveryCompanyLogo(props){
    if (props.props == "nova_posta"){
        return (
            <div>
                <img src={NovaPostaLogo} width="30%"/>
            </div>
        )
    };
    if (props.props == "courier_rapid"){
        return (
            <div>
                <img src={CourierRapidLogo} width="40%"/>
            </div>
        )
    };

    if (props.props == "fan_courier"){
        return (
            <div>
                <img src={FanCourierLogo} width="40%"/>
            </div>
        )
    };

    return (
        <div>
            logo Delivery Company
        </div>
    )

}


export default function NewOrder(props) {
    const classes = useStyles();

    console.log("props: ", props)

    const [state, setState] = React.useState({
        deliveryCompany: props.newOrder.deliveryCompany,
        cargoType: props.newOrder.cargoType,
        serviceType: props.newOrder.serviceType,
        selectedShipingPoint: props.newOrder.selectedShipingPoint,
        cityRecipient: props.newOrder.cityRecipient,
        recipient: {
            recipientBuilding: '',
            recipientStreet: {},
            recipientFirstName: '',
            recipientLastName: '',
            recipientPhone: '',
            recipientEmail: '',
        },

        packageParams: props.newOrder.packageParams,

        cargoDescription: '',
        declaredCost: 0,
        payerType: 'Receiver',
        cost: props.newOrder.cost,
        from: props.newOrder.from,
        to: props.newOrder.to,
    });

    const [error, setError] = React.useState({
        status: false,
        msg: "Error"
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
    
        });
      };

    const setStreet = (streetInput) => {
        setState({
            ...state,
            recipientStreet: streetInput,
            });
    };
    let navigate = useNavigate();
    const createOrder = () => {
        console.log("xaхa переделывай");
        console.log(state);

        let weight = 0
        let length = 0
        let width = 0
        let height = 0
        let seatAmount = 1

        if (state.cargoType === "Documents"){
            weight = state.packageParams.weight;
            seatAmount = state.packageParams.seatAmount;
        }

        if (state.cargoType === "Cargo"){
            state.packageParams.map((cargo) => {
                weight = weight + cargo.weight;
                length = length + cargo.length;
                width = width + cargo.width;
                height = height + cargo.height;
            });
            seatAmount = state.packageParams.length;
        }

        let newOrderParam = {
            deliveryCompany: state.deliveryCompany,
            CargoType: state.cargoType,
            serviceType: state.serviceType,
            payerType: state.payerType,
            paymentMethod: "Cash", // ???
            
            
            SeatsAmount: parseInt(seatAmount),
            cargoDescription: state.cargoDescription,
            declaredCost: parseInt(state.declaredCost),

            weight: parseFloat(weight),
            length: length,
            width: width,
            height: height,

            // TODO: processing delivery to post office
            sender: state.selectedShipingPoint,

            recipient: {
                recipientFirstName:  state.recipient.recipientFirstName,
                recipientLastName:  state.recipient.recipientLastName,
                recipientCity: state.cityRecipient,
                recipientStreet:  state.recipient.recipientStreet,
                recipientBuilding: state.recipient.recipientBuilding,
                recipientPhone:  state.recipient.recipientPhone,
                recipientEmail:  state.recipient.recipientEmail,
            },

            // TODO: if we select from already existing recipients add its id and address id

        };
        console.log("new order: ", newOrderParam);

        createNewDeliveryOrder(newOrderParam)
        .then(function (response) {
            // console.log("new order - success", response.orderId);
            navigate(`/client/order/${response.orderId}`);

        })
        .catch(function (error) {
            // TODO: processing error
            // 422 -- fill all fields
            setError({
                status: true,
                msg:  "Ошибка, проверьте правильность заполнения всех полей",
            });
            console.log("from getPrises errror", error);
        })
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setError({
          status: false,
          msg: "Error"
      });
    };

    console.log("state: ", state)

    return (
        <div>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={4} lg={4} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            < DeliveryCompanyLogo props={state.deliveryCompany}/> 
                        </FormControl>
                    </Grid>
                    <Grid item md={4} lg={4} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                                <Typography variant="h3" color="textSecondary" component="p">
                                    MLD: { state.cost }
                                </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} lg={4} sm={12}>
                        <Button variant="text" color="secondary" >&#60; Вернуться к рассчёту</Button>
                    </Grid>
                </Grid>
            </Box>

            <Box>

            <Grid container spacing={2}>
                    
                    <Grid item md={6} lg={6} sm={12} xs={12}>

                        <Grid container spacing={2}>
                            <Grid item md={6} lg={6} sm={6} xs={6}>
                                <Typography
                                    component="p"
                                    variant='h5'
                                    color="inherit"
                                    gutterBottom
                                >
                                    Откуда:
                                </Typography>
                            </Grid> 
                            <Grid item md={6} lg={6} sm={6} xs={6}>
                                {state.from  === 'door' && 
                                    <MeetingRoomIcon />
                                }
                                {state.from  === 'office' && 
                                    <StoreIcon />
                                }
                            </Grid>
                        </Grid>


                        <Divider light />
                        <Box>
                            { state.from === 'door' && 
                                <FromDoorBlock selectedShipingPoint={state.selectedShipingPoint} /> }
                            { state.from === 'office' &&
                                <FromOfficeBlock /> }
                            { state.from === '' &&
                                <FromDoorBlock selectedShipingPoint={state.selectedShipingPoint} /> }
                        </Box>

                    </Grid> 

                    <Grid item md={6} lg={6} sm={12} xs={12}>

                        <Grid container spacing={2}>
                            <Grid item md={6} lg={6} sm={12} xs={12}>
                                <Typography
                                    component="p"
                                    variant='h5'
                                    color="inherit"
                                    gutterBottom
                                >
                                    Куда:
                                </Typography>
                            </Grid>
                            <Grid item md={6} lg={6} sm={12} xs={12}>
                                {state.to  === 'door' && 
                                    <MeetingRoomIcon />
                                }
                                {state.to  === 'office' && 
                                    <StoreIcon />
                                }
                            </Grid>
                        </Grid>

                        <Divider light />

                        <Box>
                            { state.to === 'door' && 
                                <ToDoorBlock 
                                    cityRecipient={state.cityRecipient}
                                    recipient={state.recipient}
                                    setState={setState} 
                            /> }
                            { state.to === 'office' &&
                                <ToOfficeBlock cityRecipient={state.cityRecipient} /> }
                            { state.to === '' &&
                                    <ToDoorBlock 
                                    cityRecipient={state.cityRecipient}
                                    recipient={state.recipient}
                                    setState={setState} 
                            /> }
                        </Box>
                    
                    </Grid>
                </Grid>

            </Box>



            <Box>{/*       Order info block         */}
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={6} xs={6}>
                        <Typography
                            className={classes.mainBlockTitle}
                            component="p"
                            variant='h5'
                            color="inherit"
                            gutterBottom
                        >
                            Инфо заказа:
                        </Typography>
                    </Grid>

                    <Grid item md={6} lg={6} sm={6} xs={6}>
                        <Typography
                            className={classes.mainBlockTitle}
                            component="p"
                            variant='h5'
                            color="inherit"
                            gutterBottom
                        >
                            {state.cargoType === "Cargo" ? "Посылка" : "Документы"}
                        </Typography>
                    </Grid>
                </Grid>

                {state.cargoType === "Cargo" && 
                    <CargoView cargoProps={state.packageParams} />
                }

                {state.cargoType === "Documents" && 
                    <DocumentsView documentProps={state.packageParams} />
                }



                <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Информация об отправлении"
                                value={state.cargoDescription}
                                onChange={handleChange}
                                name="cargoDescription"
                                id="formatted-numberformat-input"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>


                <Grid item md={3} lg={3} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Объявленная стоимость"
                                value={state.declaredCost}
                                onChange={handleChange}
                                name="declaredCost"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    inputComponent: NumberFormatCustomCost,
                                }}
                            />
                        </FormControl>
                    </Grid>


                    <Grid item md={3} lg={3} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                label="Платильщик"
                                value={state.payerType}
                                onChange={handleChange}
                                name="payerType"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <Box >
                
                <div  align='center'>
                    {/* // TODO: Add  validation for button */}
                    <Button  
                         
                        variant="contained" color="primary" size='large' 
                        onClick={ () => createOrder() }
                        >
                        Оформить доставку
                    
                    </Button>
                </div>

            </Box>
          

            <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                        {error.msg}
                </Alert>
            </Snackbar>

        </div>
    )
}



function FromDoorBlock(props){
    const classes = useStyles();

    return(
        <Box> {/*// from door block */}
            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Отделение"
                            value={props.selectedShipingPoint.name}
                            // onChange={handleChange}
                            name="shipingPointName"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Город"
                            value={props.selectedShipingPoint.city}
                            // onChange={handleChange}
                            name="senderCity"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item md={9} lg={9} sm={9} xs={9}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Улица"
                            value={props.selectedShipingPoint.street}
                            // onChange={handleChange}
                            name="senderStreet"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} lg={3} sm={3} xs={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Дом"
                            value={props.selectedShipingPoint.building}
                            // onChange={handleChange}
                            name="senderBuilding"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Фамилия и Имя"
                            value={props.selectedShipingPoint.contact_first_name + " " + props.selectedShipingPoint.contact_last_name}
                            // onChange={handleChange}
                            name="senderName"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Телефон"
                            value={props.selectedShipingPoint.contact_phones}
                            // onChange={handleChange}
                            name="senderPhone"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Email"
                            value={props.selectedShipingPoint.contact_email}
                            // onChange={handleChange}
                            name="senderEmail"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}


function FromOfficeBlock(props){
    const classes = useStyles();

    return(
        <div>Из офиса курьерской компании</div>
    )
}


function ToDoorBlock(props){
    const classes = useStyles();

    const setRecipientStreet = (newRecipientStreet) => {
        console.log("newRecipientStreet: ", newRecipientStreet)
        props.setState((prev) => {
            return {
                ...prev,
                recipient: {
                    ...prev.recipient,
                    recipientStreet: newRecipientStreet,
                },
            }
        })
    }

    const setRecipientParam = (event) => {
        console.log("new recipient param: ", event)
        props.setState((prev) => {
            return {
                ...prev,
                recipient: {
                    ...prev.recipient,
                    [event.target.name]: event.target.value,
                },
            }
        })
    }

    return(
        <Box> {/* // to door block */}

            <Grid container spacing={2}>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Город"
                            value={props.cityRecipient.title}
                            // onChange={props.handleChange}
                            name="recipientCity"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>

                <Grid item md={9} lg={9} sm={9} xs={9}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <ChooseStreet 
                            cityId={props.cityRecipient} 
                            setParam={setRecipientStreet} 
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} lg={3} sm={3} xs={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Дом"
                            value={props.recipient.recipientBuilding}
                            onChange={setRecipientParam}
                            name="recipientBuilding"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Имя"
                            value={props.recipient.recipientFirstName}
                            onChange={setRecipientParam}
                            name="recipientFirstName"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Фамилия"
                            value={props.recipient.recipientLastName}
                            onChange={setRecipientParam}
                            name="recipientLastName"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Телефон"
                            value={props.recipient.recipientPhone}
                            onChange={setRecipientParam}
                            name="recipientPhone"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Email"
                            value={props.recipient.recipientEmail}
                            onChange={setRecipientParam}
                            name="recipientEmail"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}


function ToOfficeBlock(props) {
    const classes = useStyles();

    return(
        <Box>
            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Выберите отделение"
                            // value={props.cityRecipient.title}
                            // onChange={handleChange}
                            name="recipientCity"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>

                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Город"
                            value={props.cityRecipient.title}
                            // onChange={handleChange}
                            name="recipientCity"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>

                <Grid item md={9} lg={9} sm={9} xs={9}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Улица"
                            // value={props.cityRecipient.title}
                            // onChange={handleChange}
                            name="recipientCity"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} lg={3} sm={3} xs={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Дом"
                            // value={props.cityRecipient.title}
                            // onChange={handleChange}
                            name="recipientCity"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>

                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Имя"
                            // value={state.recipientFirstName}
                            // onChange={handleChange}
                            name="recipientFirstName"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Фамилия"
                            // value={state.recipientLastName}
                            // onChange={handleChange}
                            name="recipientLastName"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Телефон"
                            // value={state.recipientPhone}
                            // onChange={handleChange}
                            name="recipientPhone"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>


                <Grid item md={6} lg={6} sm={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                            label="Email"
                            // value={state.recipientEmail}
                            // onChange={handleChange}
                            name="recipientEmail"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{

                            }}
                        />
                    </FormControl>
                </Grid>

            </Grid>
        </Box>
    )
}



function CargoView(props){
    const classes = useStyles();
    let cargo = props.cargoProps;

    return(
    <div>   
        {cargo.map((cargo, index) => {
            return(
                <div>
                    <Grid 
                container spacing={2}   
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Grid item md={2} lg={2} sm={12} xs={12}>
                    <FormControl variant="outlined" 
                        className={classes.formControl}
                    >
                        <TextField
                            disabled
                            label="Вес"
                            value={props.cargoProps[index].weight}
                            // onChange={(event) => handleChange(event, index)}
                            name="weight"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                'aria-label': 'weight',
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FitnessCenterIcon />
                                    </InputAdornment>
                                    ),
                                endAdornment: (<InputAdornment position="end">Kg</InputAdornment>)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} lg={3} sm={12} xs={12} >
                    <FormControl variant="outlined" 
                    className={classes.formControl}
                    >
                    
                        <TextField
                            disabled
                            label="Длина"
                            value={props.cargoProps[index].length}
                            // onChange={(event) => handleChange(event, index)}
                            name="length"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                'aria-label': 'weight',
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PanoramaHorizontalIcon />
                                    </InputAdornment>
                                    ),
                                endAdornment: (<InputAdornment position="end">cm</InputAdornment>)
                            }}
                        />
                    </FormControl>
                </Grid>
            <Grid item md={3} lg={3} sm={12} xs={12}>
                    <FormControl variant="outlined" 
                    className={classes.formControl}
                    >
                        <TextField
                            disabled
                            label="Ширина"
                            value={props.cargoProps[index].width}
                            // onChange={(event) => handleChange(event, index)}
                            name="width"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                'aria-label': 'weight',
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PanoramaVerticalIcon />
                                    </InputAdornment>
                                    ),
                                endAdornment: (<InputAdornment position="end">cm</InputAdornment>)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} lg={3} sm={12} xs={12}>
                    <FormControl variant="outlined" 
                    className={classes.formControl}
                    >
                        <TextField
                            disabled
                            label="Высота"
                            value={props.cargoProps[index].height}
                            // onChange={(event) => handleChange(event, index)}
                            name="height"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                'aria-label': 'weight',
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HeightIcon />
                                    </InputAdornment>
                                    ),
                                endAdornment: (<InputAdornment position="end">cm</InputAdornment>)
                            }}
                        />
                    </FormControl>
                </Grid>
                {/* <Grid item md={1} lg={1} sm={12} xs={12} align="center">
    
                
                    <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    size="small"
                    onClick={addCargo}
                    >
                        <AddIcon />
                    </IconButton>
    
                    <IconButton 
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    size="small"
                    onClick={() => delCargo(index)}
                    style={{ visibility: props.cargoProps.length > 1 ? 'visible' : 'hidden'}}
                    >
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                </Grid> */}
    
                    </Grid>
                    <Divider light />
                </div>
            );
        })}
    </div>
    )
}


function DocumentsView(props){
    const classes = useStyles();
    // console.log("enter in def")
    // // function handleChangeWeightParam(event){
    // //     props.setDocsWeight(event.target.value)
    // // }

    // // function handleChangeAmountParam(event){
    // //     props.setDocsAmount(event.target.value)
    // // }

    return(
        <div>
            <Grid 
                container spacing={2}   
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Grid item md={4} lg={4} sm={12} xs={12}>
                    <FormControl variant="outlined" 
                    className={classes.formControl}
                    >
                        <TextField 
                            disabled
                            label="Вес"
                            value={props.documentProps.weight}
                            // onChange={handleChangeWeightParam}
                            name="weight"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                'aria-label': 'weight',
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                      <FitnessCenterIcon />
                                    </InputAdornment>
                                  ),
                                endAdornment: (<InputAdornment position="end">Kg</InputAdornment>)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} lg={3} sm={12} xs={12}>
                    <FormControl variant="outlined" 
                    className={classes.formControl}
                    >
                        <TextField
                            disabled
                            label="Количество мест"
                            value={props.documentProps.seatAmount}
                            // onChange={handleChangeAmountParam}
                            name="seatAmount"
                            id="formatted-numberformat-input"
                            variant="outlined"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                    <InputAdornment position="start">
                                      <FormatListNumberedOutlinedIcon />
                                    </InputAdornment>
                                  ),
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
}