import React from 'react';
import OrdersTable from './dashboard/OrdersTable';
import Cards from './dashboard/Cards';
import NewOrderBtn from './dashboard/NewOrderBtn';
import { makeStyles } from '@material-ui/core/styles';
import { getAllMyOrders } from '../../services/requests/ClientApi'
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    cards: {
        padding: theme.spacing(6)
    },
    table: {
        padding: theme.spacing(6)
    },
    newOrderBtn: {

    }
}));


function getOrdersByClientId(orders, displayStatus){
    let displayOrders = [];

    if (displayStatus == 'completed'){
        
        orders.forEach((order) => {
            if (order.status === 'completed'){
                // console.log(order);
                displayOrders.push(order);
            }
        });
        
        return displayOrders
    };
    if (displayStatus == 'delivering'){
        orders.forEach((order) => {
            if (order.status === 'delivering'){
                displayOrders.push(order);
            }
        });

        return displayOrders
    };
    if (displayStatus == 'scheduled'){
        orders.forEach((order) => {
            if (order.status === 'scheduled'){
                displayOrders.push(order);
            }
        });

        return displayOrders
    };
    if (displayStatus == 'canceled'){
        orders.forEach((order) => {
            if (order.status === 'canceled'){
                displayOrders.push(order);
            }
        });

        return displayOrders
    };
    return orders;
};

function calcClientStats(orders){
    let stats = {
        completed: 0,
        delivering: 0,
        scheduled: 0,
        canceled: 0
    }
    orders.forEach(function(order) {
        if (order.status === 'completed'){
            stats.completed ++;
        };
        if (order.status === 'delivering'){
            stats.delivering ++;
        };
        if (order.status === 'scheduled'){
            stats.scheduled ++;
        };
        if (order.status === 'canceled'){
            stats.canceled ++;
        };
    });
    return stats;
};

export default function ClientDashboard() {
    const classes = useStyles();
    const [isWaiting, setIsWaiting] = React.useState(true);
    const [displayStatus, setDisplayStatus] = React.useState(null);
    // get from API
    const [clientOrders, setClientOrders] = React.useState([]);

    React.useEffect(() => {

        getAllMyOrders()
        .then(function (response) {

            // console.log("from get all orders success", response.orders);
            setClientOrders(response.orders);
            
            setIsWaiting(false);
            
        })
        .catch(function (error) {
            // TODO: processing error

            console.log("from getPrises errror", error);
        })


    }, []);




    // let clientDisplayOrders = getOrdersByClientId(clientOrders, displayStatus);   
    // let clientStats = calcClientStats(clientOrders);
    if (isWaiting) {
        return (
            <div align='center'>
                <CircularProgress />
            </div>
        )
    }
    return (
        <div>
            {/* <div className={classes.cards}>
                <Cards stats={clientStats} setDisplayStatus={setDisplayStatus}/>
            </div> */}
            <div className={classes.newOrderBtn}>
                <NewOrderBtn />
            </div>
            <div className={classes.table}>
                <OrdersTable orders={clientOrders}/> 
            </div>
        </div>
    )
}

