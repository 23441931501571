import React from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';


const columns = [
    { field: 'id', headerName: '№', width: 110 },
    {
      field: 'orderDate',
      headerName: 'Дата заказа',
      type: 'date',
      width: 130,
    //   editable: true,
    },
    {
      field: 'recipientName',
      headerName: 'Получатель',
      width: 250,
    //   editable: true,
    },
    {
        field: 'orderName',
        headerName: 'Название заказа',
        width: 350,
      //   editable: true,
    },
    {
      field: 'deliveryAddress',
      headerName: 'Адрес Доставки',
    //   type: 'number',
      width: 310,
    //   editable: true,
    },
    {
        field: 'deliveryman',
        headerName: 'Доставщик',
        // type: 'number',
        width: 250,
        //   editable: true,
    },
    {
        field: 'status',
        headerName: 'Статус',
        // type: 'number',
        width: 150,
        //   editable: true,
    },
    {
        field: 'changeLink',
        headerName: ' ',
        // type: 'number',
        width: 110,
        sortable: false,
        renderCell: () => (

                <IconButton>
                    <EditIcon fontSize="inherit" />
                </IconButton>

        //   editable: true,
        )},
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'firstName') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
  ];
  
  const rows = [
    { id: 1, orderDate: new Date(2022, 1, 11), orderName: 'TV', recipientName: 'Snow', deliveryAddress: 'Chisinau, улица Х, дом N', deliveryman: 'Jon', status: 'DONE',},
    { id: 2, orderDate: new Date(2022, 1, 12), orderName: 'TV', recipientName: 'Lannister', deliveryAddress: 'Chisinau, улица Х, дом N', deliveryman: 'Cersei', status: 'DONE',},
    { id: 3, orderDate: new Date(2022, 1, 13), orderName: 'TV', recipientName: 'Lannister', deliveryAddress: 'Chisinau, улица Х, дом N', deliveryman: 'Jaime', status: 'DONE',},
    { id: 4, orderDate: new Date(2022, 1, 13), orderName: 'TV', recipientName: 'Stark', deliveryAddress: 'Chisinau, улица Х, дом N', deliveryman: 'Arya', status: 'DONE',},
    // { number: 5, recipientName: 'Targaryen', deliveryman: 'Daenerys', age: null },
    // { number: 6, recipientName: 'Melisandre', deliveryman: null, age: 150 },
    // { number: 7, recipientName: 'Clifford', deliveryman: 'Ferrara', age: 44 },
    // { number: 8, recipientName: 'Frances', deliveryman: 'Rossini', age: 36 },
    // { number: 9, recipientName: 'Roxie', deliveryman: 'Harvey', age: 65 },
    // { number: 10, recipientName: 'Lannister', deliveryman: 'Cersei', age: 42 },
    // { number: 11, recipientName: 'Lannister', deliveryman: 'Jaime', age: 45 },
    // { number: 12, recipientName: 'Stark', deliveryman: 'Arya', age: 16 },
    // { number: 13, recipientName: 'Targaryen', deliveryman: 'Daenerys', age: null },
    // { number: 14, recipientName: 'Melisandre', deliveryman: null, age: 150 },
    // { number: 15, recipientName: 'Clifford', deliveryman: 'Ferrara', age: 44 },
    // { number: 16, recipientName: 'Frances', deliveryman: 'Rossini', age: 36 },
    // { number: 17, recipientName: 'Roxie', deliveryman: 'Harvey', age: 65 },
  ];



export default function Orders() {
    return (
        <>
            <div> Список Заказов </div>
            < br />
            <div style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                rows={rows}
                columns={columns}
                pageSize={18}
                //   components={{
                //     Toolbar: GridToolbar,
                //   }}
                //   checkboxSelection
                //   disableSelectionOnClick
                />
            </div>
        </>

    )
}
