import React from 'react';
import { useParams } from "react-router-dom";
import { TextField, Grid, Typography, Divider, 
    InputLabel, FormControl, Select, Box, Button, Paper,
    TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, FormControlLabel, Checkbox } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import { addDeliveryCompanies } from '../../services/requests/ClientApi'
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import DeliveryCompanyChoose from './dashboard/DeliveryCompanyChoose';

const useStyles = makeStyles((theme) => ({
    mainBlockTitle: {
        left: '405px',
        top: '236px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#1E1E23',
        marginTop: theme.spacing(6),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
        // minWidth: 120,
    },
    calcBtnBlock: {
        '& > *': {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        //   textAlign: 'center'
        },
      },
    newOrderBnt:{
        width: '25%'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddNewDeliveryCompany() {

    const classes = useStyles();
    let navigate = useNavigate();
    const [credentials, setCredentials] = React.useState({
        deliveryCompany: '',
        apiKey: '',
        login: '',
        password: '',
        showPassword: false,
    });

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        msg: "Error"
    });
    const [visibleReturn, setVisibleReturn] = React.useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        setCredentials({
            ...credentials,
            [name]: event.target.value,
          });
        setVisibleReturn(false);
      };

    const setDeliveryCompany = (deliveryCompany) => {
        setCredentials({
            ...credentials,
            deliveryCompany: deliveryCompany,
            apiKey: '',
            login: '',
            password: '',
            });
        setVisibleReturn(false);
    };

    const handleClickShowPassword = () => {
        setCredentials({ ...credentials, showPassword: !credentials.showPassword });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const addDeliveryCompany = (event) => {
        let param = {
            deliveryCompany: credentials.deliveryCompany,
            apiKey: credentials.apiKey,
            login: credentials.login,
            password: credentials.password,
        }
        addDeliveryCompanies(param)
        .then(function (response) {
            // console.log("from def success", response);
            setOpenSuccess(true);
            setVisibleReturn(true);
            

          })
        .catch(function (error) {
            // console.log("error: ", error.response.data.detail); // => the response payload 
            setError({
                status: true,
                msg:  error.response.data.detail
            });
          })

    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSuccess(false);
        setError({
          status: false,
          msg: "Error"
      });
    };

  return (
        <div>

                <Grid container spacing={3}>
                    <Grid item md={6} lg={6}  sm={12} align='center'>
                        <DeliveryCompanyChoose setDeliveryCompany={setDeliveryCompany} />
                    </Grid>
                    <Grid item md={5} lg={6} sm={12} align='center'>

                        <Grid container spacing={3}>
                            <Grid item md={12} lg={12} sm={12} align='center'>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Api key"
                                        name='apiKey'
                                        value={credentials.apiKey}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputProps={{
                                            // readOnly: true,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} lg={6} sm={12} align='center'>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Логин"
                                        name='login'
                                        value={credentials.login}
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputProps={{
                                            // readOnly: true,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} lg={6} sm={12} align='center'>
                                {/* <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"> */}
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={credentials.showPassword ? 'text' : 'password'}
                                        value={credentials.password}
                                        onChange={handleChange}
                                        name="password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {credentials.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.calcBtnBlock}  align='center'>
                    {/* // TODO: Add  validation for button */}
                    <Button  
                        className={classes.newOrderBnt} 
                        variant="contained" color="primary" size='large' disableElevation
                        onClick={(event) => addDeliveryCompany(event)}
                        // startIcon={<DetailsIcon />}
                        >
                        Добавить
                    
                    </Button>

                </div>
                <div className={classes.calcBtnBlock} style={{ visibility: visibleReturn ? 'visible' : 'hidden'}} align='center' >
                    {/* // TODO: Add  validation for button */}
                    <Button  
                        // className={classes.newOrderBnt} 
                        variant="contained" color="primary" size='large' disableElevation
                        onClick={() => navigate(`/client/info`)}
                        // startIcon={<DetailsIcon />}
                        >
                        Вернуться в кабинет
                    
                    </Button>
                </div>
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                            Данные успешно добавлены.
                    </Alert>
                </Snackbar>
                <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                            {error.msg}
                    </Alert>
                </Snackbar>
        </div>
  )

}
