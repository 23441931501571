import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Container, Grid,} from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    cardIcon: {
        padding: theme.spacing(2),
        fontSize: "78px"
    }
}));


// const munbers = {
//     completed: 5,
//     delivering: 7,
//     scheduled: 3,
//     canceled: 2
// }


export default function Cards(props) {
    const classes = useStyles();

    let cardNumbers = props.stats;
    console.log(cardNumbers);

    return (
        <div>
            <Container fixed>
                <Grid container spacing={9}>
                    <Grid item md={6} lg={3} sm={12} align="center" onClick={() => props.setDisplayStatus('completed')}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CheckIcon className={classes.cardIcon} style={{ color: "#58B402" }}/>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Завершён
                                </Typography>
                                <Typography variant="h1" color="textSecondary" component="p">
                                    {cardNumbers.completed}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item md={6} lg={3} sm={12} align="center" onClick={() => props.setDisplayStatus('delivering')}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <LocalShippingOutlinedIcon className={classes.cardIcon}  style={{ color: "#06AEE7" }} />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Доставляется
                                </Typography>
                                <Typography variant="h1" color="textSecondary" component="p">
                                    {cardNumbers.delivering}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item md={6} lg={3} sm={12} align="center" onClick={() => props.setDisplayStatus('scheduled')}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <ScheduleIcon className={classes.cardIcon} style={{ color: "#725CA4" }} />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Запланирован
                                </Typography>
                                <Typography variant="h1" color="textSecondary" component="p">
                                    {cardNumbers.scheduled}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item md={6} lg={3} sm={12} align="center" onClick={() => props.setDisplayStatus('canceled')}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <BlockIcon className={classes.cardIcon}  style={{ color: "#EF3F3F" }} />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Отменён
                                </Typography>
                                <Typography variant="h1" color="textSecondary" component="p">
                                    {cardNumbers.canceled}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}



