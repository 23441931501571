import React from 'react';


import { Box, Drawer, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


import {
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
  } from "@material-ui/core";
import { NavLink } from "react-router-dom";
// import { useStyles } from "./HeaderStyle";

import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ViewListIcon from '@material-ui/icons/ViewList';
import StoreIcon from '@material-ui/icons/Store';

import Gray from '@material-ui/core/colors/grey';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";



import Dashboard from './admin/Dashboard'
import Deliverymans from './admin/Deliverymans'
import Shops from './admin/Shops'
import Orders from './admin/Orders'


const useStyles = makeStyles((theme) => ({
    adminMain: {
        height: '100%',
        marginTop: theme.spacing(6),
        backgroundColor: '#FAFBFC',
        overflow: 'hidden',
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    //sidenav
    drawerPaper: {
        width: "250px",
        marginTop: "64px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "0px",
        },
        backgroundColor: '#313238',
    },
    navlink: {
        color: Gray[50],
        transition: "0.8s",
        "&:hover  div": {
          color: Gray[50],
        },
      },
    navButton: { width: "100%", textTransform: "capitalize" },
      selectedNav: {
        color: `${Gray[50]} !important`,
        fontWeight: " bolder",
        " & div": {
          color: `${Gray[50]} !important`,
        },
    },
    adminContent: {
        width: "100%",
        minHeight: "100vh",
        height: "auto",
        background: "#efefef",
        boxSizing: "border-box",
        padding: "70px 24px 24px 270px",
        [theme.breakpoints.down("sm")]: {
          padding: "70px 24px 24px 24px",
        },
    },

}));



export default function AdminPage({
    mobileOpen,
    handleDrawerClose,
    handleDrawerToggle,
  }) {

    const classes = useStyles();

    return (
        <><nav className={classes.drawer} aria-label='mailbox folders'>
            {/* Hidden on bigger Size  */}
            <Hidden mdUp implementation='css'>
                <Drawer
                    variant='temporary'
                    anchor={"left"}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}>
                    <SidenavData handleDrawerClose={handleDrawerClose} />
                </Drawer>
            </Hidden>
            {/* visible on screen greater than 600px */}
            <Hidden smDown implementation='css'>
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant='permanent'
                    open>
                    <SidenavData handleDrawerClose={handleDrawerClose} />
                </Drawer>
            </Hidden>
        </nav>
        <Box className={classes.adminContent}>
            <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="dliveryman" element={<Deliverymans />} />
                    <Route path="shops" element={<Shops />} />
                    <Route path="orders" element={<Orders />} />
            </Routes>
        </Box>
        </>
    )
}


function SidenavData({ handleDrawerClose }) {
    const classes = useStyles();
    const listItemData = [
      { label: "Dashboard", link: "/admin", icon: <DashboardIcon /> },
      { label: "Deliveryman", link: "./dliveryman", icon: <LocalShippingIcon /> },
      { label: "Shop", link: "./shops", icon: <StoreIcon /> },
      { label: "Orders", link: "./orders", icon: <ViewListIcon /> },
    ];
  
    return (
      <List>
        {listItemData.map((item, i) => (
          <Button
            size='small'
            onClick={() => handleDrawerClose()}
            className={classes.navButton}>
            <ListItem
              exact
              key={i}
              component={NavLink}
              to={item.link}
              className={classes.navlink}
              activeClassName={classes.selectedNav}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </ListItem>
          </Button>
        ))}
      </List>
    );
  }