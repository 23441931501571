import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField, Grid, Typography, Divider, 
    InputLabel, FormControl, Select, Box, Button, Paper,
    TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, FormControlLabel, Checkbox } from '@material-ui/core';
import NovaPostaLogo from '../../assets/partners/image9.png';
import CourierRapidLogo from '../../assets/partners/logoCR.png';

import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

import { getShippingPoint, getDeliveryCompanies } from '../../services/requests/ClientApi'

export default function ClientInfo() {
    let navigate = useNavigate();
    const [isWaitingCompanies, setIsWaitingCompanies] = React.useState(true);
    const [isWaitingPoints, setIsWaitingPoints] = React.useState(true);
    const [deliveryCompanies, setDeliveryCompanies] = React.useState([]);
    const [shipingPoints, setShipingPoints] = React.useState([]);


    React.useEffect(() => {

        getShippingPoint().then(data => {
            setShipingPoints(data.shippingPoints);
            setIsWaitingPoints(false);
        });
        getDeliveryCompanies().then(data => {
            setDeliveryCompanies(data.deliveryCompanies);
            setIsWaitingCompanies(false);
        });
    }, []);
    
    if (isWaitingCompanies && isWaitingPoints) {
        return (
            <div align='center'>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div>
            <Box>

            <Grid container spacing={3}>
                <Grid item md={6} lg={6} sm={12} align='left'>
                    <Typography
                        component="h4"
                        variant='h5'
                        color="inherit"
                        gutterBottom
                    >
                        Мои курьерские компании
                    </Typography>
                </Grid>
                <Grid item md={6} lg={6} sm={12} align='right'>
                    <Button  
                        // className={classes.newOrderBnt} 
                        variant="outlined"
                        onClick={(event) => navigate("/client/new-delivery-company")}
                        startIcon={<AddIcon />}
                        >
                        Добавить новую курьерскую компанию 
                    </Button>
                </Grid>
            </Grid>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Название
                                </TableCell>
                                <TableCell>
                                    Api key
                                </TableCell>
                                <TableCell>
                                    Login
                                </TableCell>
                                <TableCell>
                                    Password
                                </TableCell>
                                <TableCell>
                                    
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deliveryCompanies
                                .map((row, index) => {
                                    return(
                                        <TableRow>
                                            <TableCell>{row.title}</TableCell>
                                            <TableCell>{row.api_key}</TableCell>
                                            <TableCell>{row.login}</TableCell>
                                            <TableCell>{row.password}</TableCell>
                                            <TableCell>
                                                <EditIcon />
                                                <DeleteForeverIcon />
                                            </TableCell>
                                        </TableRow>
                                    );
                            })}
                        </TableBody>
                    </Table>

                </div>
            </Box>

            <hr />
            <Box>
            <Grid container spacing={3}>
                <Grid item md={6} lg={6} sm={12} align='left'>
                    <Typography
                        component="h4"
                        variant='h5'
                        color="inherit"
                        gutterBottom
                    >
                        Мои адреса магазинов
                    </Typography>
                </Grid>
                <Grid item md={6} lg={6} sm={12} align='right'>
                    <Button  
                        // className={classes.newOrderBnt} 
                        variant="outlined"
                        onClick={(event) => navigate("/client/new-point")}
                        startIcon={<AddIcon />}
                        >
                        Добавить новую точку продаж
                    </Button>
                </Grid>
            </Grid>
                <div>
                <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Название
                                </TableCell>
                                <TableCell>
                                    Адрес
                                </TableCell>
                                <TableCell>
                                    Контактное лицо
                                </TableCell>
                                <TableCell>
                                    
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shipingPoints
                                .map((row, index) => {
                                    return(
                                        <TableRow>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{`${row.city}, ${row.street}, ${row.building}`}</TableCell>
                                            <TableCell>{`${row.contact_first_name} ${row.contact_last_name}`}</TableCell>

                                            <TableCell>
                                                <EditIcon />
                                                <DeleteForeverIcon />
                                            </TableCell>
                                        </TableRow>
                                    );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Box>

        </div>
    )
}
